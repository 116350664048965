@import "@fortawesome/fontawesome-free/css/all.css";
@import "mixins";
@import "itravel-tag-icons/icons.css";

// Disable Bootstrap validation icons.
// Those were introduced in version 4.3 but don't play nicely with minification right now.
// See https://github.com/twbs/bootstrap/issues/28526
// They are also rather obtrusive and don't look that well with our markdown editor.
// Disabling the icons makes it look and behave as before.
$enable-validation-icons: false;

$theme-colors: (
  "primary": #22a7a1,
  "danger": #d82420
);

$font-family-sans-serif: "Open Sans", "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

@import "~bootstrap/scss/bootstrap.scss";
@import "ol/ol.css";

// Import SCSS files here that also use bootstrap variables
@import "MarkdownEditor";

$navbar-height: 58px;

.ui-input::placeholder {
    color: #ced4da;
}

.navbar {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1000;

  &-brand {
    // Center the logo SVG in navbar
    margin-top: -4px;
  }
}

.sidebar-sticky {
  position: sticky;
  top: $navbar-height;
  height: calc(100vh - #{$navbar-height});
  overflow-y: auto;
  z-index: 100;
  border-right-width: 1 !important;
  border-bottom-width: 0 !important;

  .nav-link {
    color: theme-color(secondary);

    &:hover {
      color: theme-color(dark);
    }

    &.active {
      color: theme-color(primary);
    }
  }

  @include media-breakpoint-down(sm) {
    position: relative;
    top: 0;
    height: auto;
    border-right-width: 0 !important;
    border-bottom-width: 1px !important;

    &::before {
      content: " ";
      position: absolute;
      z-index: 10;
      pointer-events: none;
      width: 1em;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(left, $body-bg, transparentize($body-bg, 1));
    }

    &::after {
      content: " ";
      z-index: 10;
      position: absolute;
      pointer-events: none;
      width: 1em;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(left, transparentize($body-bg, 1), $body-bg);
    }

    &__inner {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }

  .nav-item {
      white-space: nowrap;
    }
  }
}

.link-tab-bar-icon {
  display: inline;
}

.content {
  max-width: map-get($container-max-widths, "xl");
  overflow-x: auto;
}

.card-header {
  color: #555;
}

html {
  overflow-y: scroll;
}

.product-image:hover .image-edit {
  display: flex;
  flex-content: center;
  align-items: center;
  justify-content: center;
}

.image-edit {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  text-align: center;
}

.fact-bar p:last-child {
  margin-bottom: 0;
}

.toasty-container {
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
  z-index: 99999;

  // Renders multiple lines of message details nicely
  .alert {
    white-space: pre-wrap;
  }
}

// PRICE TABLE

.price-table {
  &__wrapper {
    th {
      position: relative;

      p,
      ol {
        margin-bottom: 0;
      }

      .btn-toolbar {
        display: none;
        background-color: $body-bg;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
      }

      &:hover .btn-toolbar {
        display: flex;
      }
    }
  }

  &--fullscreen {
    position: absolute;
    left: 0;
    top: 0;

    .price-table__wrapper {
      position: absolute;
      left: 0;
      top: 0;
      padding-bottom: 64px;
      background-color: $body-bg;
    }

    .table {
      thead th {
        position: sticky;
        top: 0;
        background-color: $body-bg;
        z-index: 100;

        @include drop-shadow(bottom);
      }

      tbody th {
        position: sticky;
        left: 0;
        z-index: 50;
        background-color: $body-bg;

        @include drop-shadow(right);

        @include media-breakpoint-down(sm) {
          position: relative;

        }
      }

      &.table-striped tbody tr:nth-of-type(odd) {
        th {
          background-color: rgb(242, 242, 242); // 0.05 black on white
        }
      }
    }

    .price-table__actions {
      position: fixed;
      padding: 16px;
      padding-left: 366px; // width of first column as defined in Elm + 16px
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $body-bg;
      z-index: 150;

      @include drop-shadow(top);

      @include media-breakpoint-down(sm) {
        padding: 12px 16px;
        height: 64px;
        flex-direction: row !important;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        div {
          display: flex;
        }

        .btn {
          margin-right: 10px !important;
        }
      }
    }
  }

  &__button {
    &--fullscreen {
      position: fixed;
      left: 16px;
      bottom: 16px;
      z-index: 200;

      @include media-breakpoint-down(sm) {
        bottom: auto;
        top: 16px;
      }
    }
  }
}

// Some elements need to be hidden in fullscreen mode to properly support sticky positioning.
.fullscreen {
  .navbar,
  .sidebar-sticky {
    display: none;
  }
  .content {
    overflow-x: initial;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

.search-result-template__description {
  img {
    max-width: 100%;
  }
}

.ellipsify-on-row-2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
}
